import React from 'react';
import {
  AppBar,
  CssBaseline,
  Link,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import {
  BrowserRouter,
  Switch as RouterSwitch,
  Route
} from 'react-router-dom'
import {
  createTheme
} from '@mui/material/styles'

import CTLiveLogo from './CTLiveLogo';
import shadows from '@mui/material/styles/shadows';
import ThemeSwitch from './ThemeSwitch';
import LandingPage from './LandingPage';
import NotFoundPage from './NotFoundPage';
import DonatePage from './DonatePage';
import EventPage from './EventPage';

function AppRoot() {
  const darkMode = useSelector<{
    darkMode: boolean
  }, boolean>(state => state.darkMode)
  const theme = React.useMemo(
    () => createTheme({
      shadows, // Disables elevation globally
      palette: {
        background: {
          default: darkMode ? '#141723' : '#fff',
          paper: darkMode ? '#141723' : '#fff'
        },
        primary: {
          main: darkMode ? '#00B0FF' : '#00B0FF',
          contrastText: darkMode ? '#141723' : '#fff',
        },
        secondary: {
          main: darkMode ? '#5FC2A2' : '#5FC2A2',
          contrastText: darkMode ? '#141723' : '#fff'
        },
        text: {
          primary: darkMode ? '#fff' : '#424242',
        },
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: darkMode ? '#141723' : '#fff',
              color: '#fff',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              backgroundColor: '#16A9E1',
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: '#00B0FF',
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              color: darkMode ? '#fff' : '#424242',
              '& .MuiFormLabel-root': {
                color: darkMode ?  '#fff' : '#424242',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: darkMode ?  '#fff' : '#424242',
              },
              '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                '-webkit-text-fill-color': darkMode ?  '#ffffff4d !important' : '#e0e0e0 !important',
                color: darkMode ? '#ffffff4d !important' : '#e0e0e0 !important',
                borderColor:darkMode ? '#ffffff4d !important' : '#e0e0e0 !important',
              },
              '& .Mui-disabled': {
                '-webkit-text-fill-color': darkMode ?  '#ffffff4d !important' : '#e0e0e0 !important',
                color: darkMode ? '#ffffff4d !important' : '#e0e0e0 !important',
                borderColor:darkMode ? '#ffffff4d !important' : '#e0e0e0 !important',
              }
            }
          }
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
                '& .MuiTypography-root': {
                  color: darkMode ?  '#fff' : '#424242',
              },
            }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              '& .MuiButtonBase-root': {
                color: darkMode ? '#fff' : '#424242',
              },
              '& .Mui-disabled': {
                color: darkMode ?  '#ffffff4d !important' : '#e0e0e0 !important',
                '-webkit-text-fill-color': darkMode ?  '#ffffff4d !important' : '#e0e0e0 !important',
              },
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                '-webkit-text-fill-color': darkMode ?  '#ffffff4d !important' : '#e0e0e0 !important',
              },
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              '&.MuiTab-root': {
                color: darkMode ? '#fff' : '#424242',
                opacity: 0.7
              },
              '&.Mui-selected': {
                opacity: 1
              },
            },
          },
        },
      }
    }),
    [darkMode]
  )

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        margin: '0 auto',
        maxWidth: 1200,
      }}>
        <CssBaseline />
        <BrowserRouter>
          <AppBar
            elevation={0}
            position='relative'
          >
            <Toolbar sx={{justifyContent: 'space-between'}}>
              <CTLiveLogo />
              <ThemeSwitch />
            </Toolbar>
          </AppBar>
          <main>
            <div style={{ padding: '20px 40px' }}>
              <RouterSwitch>
                <Route path="/event/:eventId/charity/:charityId" component={LandingPage} />
                <Route path="/event/:eventId" component={EventPage} />
                <Route path="/donate/:eventId/charity/:charityId" component={DonatePage} />
                <Route path="*" component={NotFoundPage} />
              </RouterSwitch>
            </div>
            <div style={{
              margin: '20px auto',
              textAlign: 'center'
            }}>
              <Typography style={{
                fontSize: 12,
                overflowWrap: 'anywhere'
              }}>
                <span style={{
                lineHeight: '22px',
                whiteSpace: 'nowrap'
                }}>Registration Support E-mail: <Link href="mailto:regsupport@chronotrack.com">regsupport@chronotrack.com</Link></span>
              &nbsp;&nbsp;
              <span style={{
                  whiteSpace: 'nowrap'
                }}>Registration Support Phone: 1-888-600-2298</span>
              </Typography>
              <Typography style={{
                fontSize: 12,
                lineHeight: '22px',
                marginTop: 4,
                overflowWrap: 'anywhere',
              }}>
                <span style={{ whiteSpace: 'nowrap' }}>&copy;2009-2020 ChronoTrack.</span>
            &nbsp;
            <span style={{ whiteSpace: 'nowrap' }}>All rights reserved.</span>
            &nbsp;
            <span style={{ whiteSpace: 'nowrap' }}><Link>Privacy Policy</Link>&nbsp;|&nbsp;<Link>Terms of Service</Link></span>
              </Typography>
            </div>
          </main>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default AppRoot;
